import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Prestation } from 'src/app/models/prestation.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.css']
})
export class PrestationsComponent implements OnInit {

  @Input() prestation: Prestation;

  constructor(private router:Router) { }

  isScreenSmall = false;

  ngOnInit(): void {
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    // Considérons les écrans de moins de 768px comme petits
    this.isScreenSmall = window.innerWidth < 768;
  }

    //Redirection vers la page de devis en ligne
    goToDevis(){
      let link = ['/devis'];
      this.router.navigate(link);
    }

}
