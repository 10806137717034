<div *ngIf="prestation.isLeft || (!prestation.isLeft && isScreenSmall)" class= 'info-one'>
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 col-12">
            <!-- Start Info Text -->
            <div class="info-text wow fadeInLeft" data-wow-delay=".3s">
                <!-- <div class="main-icon">
                    <i class="lni lni-inbox"></i>
                </div> -->
                <div class="main-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img">
                        <path fill="currentColor" [attr.d]="prestation.iconeUrl"></path>
                      </svg>
                </div>
                <h2>{{prestation.titre}}</h2>
                <p>{{prestation.paragraphe}}</p>
                <div class="button">
                    <a (click)="goToDevis()" class="btn">Devis en ligne</a>
                </div>
            </div>
            <!-- End Info Text -->
        </div>
        <div class="col-lg-6 col-md-12 col-12">
            <div class="info-image wow fadeInRight" data-wow-delay=".5s">
                <img class="ss1" src={{prestation.imageUrl}} alt="#">
            </div>
        </div>
    </div>
</div>

<div *ngIf="!prestation.isLeft && !isScreenSmall" class="info-one style2">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 col-12">
            <div class="info-image wow fadeInLeft" data-wow-delay=".3s">
                <img class="ss1" src='{{prestation.imageUrl}}' alt="#">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
            <!-- Start Info Text -->
            <div class="info-text wow fadeInRight" data-wow-delay=".5s">
                <div class="main-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img">
                        <path fill="currentColor" [attr.d]="prestation.iconeUrl"></path>
                      </svg>
                </div>
                <h2>{{prestation.titre}}</h2>
                <p>{{prestation.paragraphe}}</p>
                <div class="button">
                    <a (click)="goToDevis()" class="btn">Devis en ligne</a>
                </div>
            </div>
            <!-- End Info Text -->
        </div>
    </div>
</div>