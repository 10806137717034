<!-- Start Breadcrumbs -->
<div class="breadcrumbs">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                <div class="hero-content">
                    <h2 class="wow fadeInLeft" data-wow-delay=".4s">Mentions légales</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section id="mentions-legales" class="section">
        <h4>Mentions légales :</h4>
            <ul> 
                <li>Nom de la société : Au Service des Arts</li>
                <li>Forme juridique : SAS</li>
                <li>Adresse du siège social : 78 chemin du dessus du Luet, 91230 Montgeron</li>
                <li>Numéro de téléphone : 06 68 40 69 70</li>
                <li>Adresse e-mail : auservicedesarts@outlook.com</li>
                <li>Numéro d’inscription au Registre du Commerce et des Sociétés (RCS) : Evry B 917 533 663</li>
                <li>Numéro de TVA intracommunautaire : FR88917533663</li>
                <li>Directeur de la publication : Mahy Franck</li>
                <li>Hébergeur du site : Hostinger, 61 Lordou Vironos Street, Larnaca 6023</li>
                <li>URL du site : www.auservicedesarts.com</li>
            </ul>
    </section>
        
    <section id="utilisations" class="section">
        <h4>Conditions d’utilisation :</h4>
        
        <p>Le site accessible par l’URL suivante : www.auservicedesarts.com est exploité dans le respect de la législation française. <br>L’utilisation de ce site est régie par les présentes conditions générales. En utilisant le site, vous reconnaissez avoir pris connaissance de ces conditions et les avoir acceptées. Ces dernières pourront être modifiées à tout moment et sans préavis par la société.
            Au Service des Arts ne saurait être tenu pour responsable en aucune manière d’une mauvaise utilisation du service.
        </p>
    </section>

    <section id="responsabilite" class="section">
        <h4>Limitation de responsabilité :</h4>
        <p>Les informations contenues sur ce site sont aussi précises que possible et le site est périodiquement remis à jour, mais peut toutefois contenir des inexactitudes, des omissions ou des lacunes. Si vous constatez une lacune, erreur ou ce qui paraît être un dysfonctionnement, merci de bien vouloir le signaler par email en décrivant le problème de la manière la plus précise possible à <a href="mailto:auservicedesarts@outlook.com">auservicedesarts@outlook.com</a>.</p>

        <p>Tout contenu téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, Au Service des Arts ne saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une quelconque perte de données consécutives au téléchargement. Les photos sont non contractuelles.</p>
        
        <p>Les informations et images des œuvres d’art transportées sont fournies à titre indicatif et ne sauraient engager la responsabilité de Au Service des Arts. Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de Au Service des Arts.</p>
        
        <p>Conditions spécifiques liées au transport d’œuvres d’art</p>
        
        <p>Au Service des Arts s’engage à fournir le plus grand soin dans le transport des œuvres d’art confiées par ses clients. Les conditions spécifiques de transport, y compris les assurances, les modalités de transport, les responsabilités et les restrictions éventuelles, seront définies dans un contrat de service spécifique, conclu entre Au Service des Arts et le client avant toute opération de transport.</p>
        
    </section>
        
    <section id="droits" class="section">
        <h4>Droits d’auteur :</h4>
        <p>
        L’ensemble de ce site relève de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. <br>Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques.
        </p>
    </section>

    <section id="donnees-personnelles" class="section">
        <h4>Gestion des données personnelles :</h4>
        <p>Le site peut recueillir des informations personnelles pour le besoin de certains services proposés par le site. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur du site l’obligation ou non de fournir ces informations.</p>
        <p>Conformément à la loi “Informatique et Libertés”, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant à <a href="mailto:auservicedesarts@outlook.com">auservicedesarts@outlook.com</a>.</p>        
    </section>
</div>