<header class="header navbar-area" >
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-12">
              <div class="nav-inner">
                  <!-- Start Navbar -->
                  <nav class="navbar navbar-expand-lg">
                      <a class="navbar-brand" [routerLink]="['/accueil']">
                        <div id="logo-app"></div>
                      </a>
                      <button class="navbar-toggler mobile-menu-btn" (click)="toggleNavbar()" [class.active]="isActive" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        [attr.aria-expanded]="isActive ? 'true' : 'false'" aria-label="Toggle navigation">
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                      </button>
                      <div [class]=" isActive ? 'collapse navbar-collapse sub-menu-bar show' : 'collapse navbar-collapse sub-menu-bar'" id="navbarSupportedContent">
                          <ul id="nav" class="navbar-nav ms-auto">
                              <li class="nav-item">
                                  <a [routerLink]="['/accueil']" class="page-scroll"
                                      aria-label="Toggle navigation" (click)="toggleNavbar()">Accueil</a>
                              </li>
                              <li class="nav-item">
                                  <a [routerLink]="['/accueil']" fragment="prestations" class="page-scroll"
                                      aria-label="Toggle navigation" (click)="toggleNavbar()">Prestations</a>
                              </li>
                              <li class="nav-item">
                                  <a [routerLink]="['/accueil']" fragment="services" class="page-scroll" aria-label="Toggle navigation" (click)="toggleNavbar()">Services</a>
                              </li>
                              <li class="nav-item">
                                  <a [routerLink]="['/accueil']" fragment="equipe" class="page-scroll" aria-label="Toggle navigation" (click)="toggleNavbar()">Equipe</a>
                              </li>
                              <li class="nav-item">
                                  <a [routerLink]="['/accueil']" fragment="contactes" class="page-scroll" aria-label="Toggle navigation" (click)="toggleNavbar()">Contacts</a>
                              </li>
                          </ul>
                      </div> <!-- navbar collapse -->
                      <div class="button add-list-button">
                          <a (click)="goToDevis()" class="btn">Devis en ligne</a>
                      </div>
                  </nav>
                  <!-- End Navbar -->
              </div>
          </div>
      </div> <!-- row -->
  </div> <!-- container -->
</header>