import { Prestation } from "src/app/models/prestation.model";
import { NosServices } from "src/app/models/nos-services.model";
import { Membre } from "src/app/models/membre.model";
import { LogoPartenaire } from "src/app/models/logo-partenaires.model";

export const PRESTATIONS_VALUES : Prestation [] = [
    {titre : 'Transport sécurisé d\'œuvres d\'art', paragraphe: `Spécialisés dans le transport sécurisé d’œuvres d’art, qu’il s’agisse de tableaux, de sculptures ou de tout objet d’art précieux (mobilier, antiquités, joaillerie…), nous opérons dans toute l’Europe, garantissant un transport reconnu, sûr et efficace.`, isLeft : true, imageUrl :'../../../../assets/img/Transport2.jpeg', iconeUrl : 'M20 8h-3V4H1v13h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5M15 7h-2v7h-2V7H9v7H7V7H5v7H3V6h12zm3 11.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5M17 12V9.5h2.5l1.96 2.5z'},
    {titre : 'Emballage et conditionnement', paragraphe: 'Nous proposons des services de mise en caisse pour les transports et les œuvres le nécessitant. Nos caisses sont conçues sur mesure pour chaque œuvre, celle-ci sera parfaitement protégée pendant le transport.', isLeft : false, imageUrl :'../../../../assets/img/Emballage3.jpeg', iconeUrl : 'M2 10.96a.985.985 0 0 1-.37-1.37L3.13 7c.11-.2.28-.34.47-.42l7.83-4.4c.16-.12.36-.18.57-.18c.21 0 .41.06.57.18l7.9 4.44c.19.1.35.26.44.46l1.45 2.52c.28.48.11 1.09-.36 1.36l-1 .58v4.96c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18c-.21 0-.41-.06-.57-.18l-7.9-4.44A.991.991 0 0 1 3 16.5v-5.54c-.3.17-.68.18-1 0m10-6.81v6.7l5.96-3.35zM5 15.91l6 3.38v-6.71L5 9.21zm14 0v-3.22l-5 2.9c-.33.18-.7.17-1 .01v3.69zm-5.15-2.55l6.28-3.63l-.58-1.01l-6.28 3.63z'},
    {titre : 'Stockage', paragraphe: 'Nous offrons des solutions de stockage sécurisées, nos installations sont climatisées et sécurisées (18 degrés, vidéosurveillance, gardiennage), garantissant la plus haute sécurité et préservation des œuvres.', isLeft : true, imageUrl :'../../../../assets/img/Stockage6.jpeg', iconeUrl : 'M13.152.682a2.251 2.251 0 0 1 2.269 0l.007.004l6.957 4.276a2.277 2.277 0 0 1 1.126 1.964v7.516c0 .81-.432 1.56-1.133 1.968l-.002.001l-11.964 7.037l-.004.003c-.706.41-1.578.41-2.284 0l-.026-.015l-6.503-4.502a2.268 2.268 0 0 1-1.096-1.943V9.438c0-.392.1-.77.284-1.1l.003-.006l.014-.026c.197-.342.48-.627.82-.827h.002L13.152.681Zm.757 1.295h-.001L2.648 8.616l6.248 4.247a.775.775 0 0 0 .758-.01h.001l11.633-6.804l-6.629-4.074a.75.75 0 0 0-.75.003ZM8.517 14.33a2.286 2.286 0 0 1-.393-.18l-.023-.014l-6.102-4.147v7.003c0 .275.145.528.379.664l.025.014l6.114 4.232zM18 9.709l-3.25 1.9v7.548L18 17.245Zm-7.59 4.438l-.002.002a2.296 2.296 0 0 1-.391.18v7.612l3.233-1.902v-7.552Zm9.09-5.316v7.532l2.124-1.25a.776.776 0 0 0 .387-.671V7.363Z'},
    {titre : 'Accrochage et installation', paragraphe: `Une fois arrivées à destination, nous nous chargeons également de l’accrochage de vos œuvres d’art. Elles seront installées selon vos directives, qu’il s’agisse d’une exposition temporaire ou d’une installation permanente (accrochage sur tringles, sur mur, etc.).`, isLeft : false, imageUrl :'../../../../assets/img/Accrochage5.jpeg', iconeUrl : 'M4.35.577a7 7 0 0 1 9.381 4.085c.27.758.15 1.626-.315 2.282A2.526 2.526 0 0 1 11.37 8H9.5a1.5 1.5 0 0 0-.455 2.931c.55.205.935.702.972 1.286a1.43 1.43 0 0 1-1.01 1.524A6.8 6.8 0 0 1 7.129 14a7 7 0 0 1-3.636-1.021A7.055 7.055 0 0 1 .15 6.517a7.055 7.055 0 0 1 4.2-5.94M4.5 7a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3m6-3a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0m-6 6.5a1 1 0 1 0 0-2a1 1 0 0 0 0 2'},
]

export const NOS_SERVICES_VALUES : NosServices [] = [
    {titre : 'Douane et Beaux-arts', paragraphe: 'Nous faisons appel à un gestionnaire de douane pour effectuer les déclarations en douane import/export.', icone : 'm9.2 15.6l2.8-2.1l2.75 2.1l-1.05-3.4l2.8-2.2h-3.4L12 6.6L10.9 10H7.5l2.75 2.2zM12 22q-3.475-.875-5.737-3.988T4 11.1V5l8-3l8 3v6.1q0 3.8-2.262 6.913T12 22m0-2.1q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.25l-6 2.25V11.1q0 3.025 1.7 5.5t4.3 3.3m0-7.9'},
    {titre : 'Sécurité', paragraphe: '“Au service des arts” : expertise et discrétion pour un transport d’art sécurisé. Vos œuvres voyagent avec soin et sérénité.', icone : 'M12 22q-3.475-.875-5.738-3.988T4 11.1V5l8-3l8 3v6.1q0 3.8-2.262 6.913T12 22'},
    {titre : 'Assurance', paragraphe: 'À votre demande, une assurance ad valorem permettant d’assurer l’œuvre à sa valeur estimée pourra vous être proposée.', icone : 'M12 18q1.725-.425 2.863-1.987T16 12.55V10l-4-2l-4 2v2.55q0 1.9 1.138 3.463T12 18m-8 3V9l8-6l8 6v12zm2-2h12v-9l-6-4.5L6 10zm6-6.75'},
]

export const ASSOCIEES_VALUES : Membre [] = [
    {nomComplet : 'PISTOL Karen', poste : 'Associé', photoUrl : '../../assets/img/KarenProfil.jpeg', twitterUrl : '', linkedinUrl:''},
    {nomComplet : 'SARRE Kader', poste : 'Développeur & Associé', photoUrl : '../../assets/img/KaderProfil.jpeg', twitterUrl : '', linkedinUrl:''},
    {nomComplet : 'MAHY Kim', poste : 'Associé', photoUrl : '../../assets/img/KimProfil.jpeg', twitterUrl : '', linkedinUrl:''},
]

export const DIRECTEURS_VALUES : Membre [] = [
    {nomComplet : 'MAHY Franck', poste : 'Président', photoUrl : '../../assets/img/FranckProfil3.jpeg', twitterUrl : '', linkedinUrl:''},
    {nomComplet : 'SALOMON Armand', poste : 'Directeur Général', photoUrl : '../../assets/img/ArmandProfil4.jpeg', twitterUrl : '', linkedinUrl:''},
    {nomComplet : 'SALOMON Thierry', poste : 'Directeur Commercial', photoUrl : '../../assets/img/ThierryProfil.jpeg', twitterUrl : '', linkedinUrl:''},
]

export const LOGO_VALUES : LogoPartenaire [] = [
    {nomPartenaire : 'Christie\'s',  photoUrl : '../../assets/img/LogoChristies.jpeg'},
    {nomPartenaire : 'Natalie Seroussi',  photoUrl : '../../assets/img/LogoNatalie.jpeg'},
    {nomPartenaire : 'Galerie Taménaga',  photoUrl : '../../assets/img/LogoTamenaga.jpeg'},
    {nomPartenaire : 'Helene Bailly',  photoUrl : '../../assets/img/LogoHelene.jpeg'},
]