<!-- Start Single Team -->
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
    <div class="image">
        <img [src]='membre.photoUrl' alt="#" width="350" height="280">
    </div>
    <div class="content">
        <div class="row align-items-center">
            <div class="col-lg-7 col-12">
                <div class="text">
                    <h3><a href="javascript:void(0)">{{membre.nomComplet}}</a></h3>
                    <h5>{{membre.poste}}</h5>
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <ul class="social">
                    <li><a href="javascript:void(0)"><i class="lni lni-twitter-original"></i></a>
                    </li>
                    <li><a href="javascript:void(0)"><i class="lni lni-linkedin-original"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Single Team -->