<footer id="footer">
    <div class="footer-dark">
        <div class="container liste-center">
            <div class="row">
                <div class="col-sm-6 col-md-4 col-lg-4 item">
                    <h3>Services</h3>
                    <ul>
                        <li><a [routerLink]="['/accueil']" fragment="prestations">Transport</a></li>
                        <li><a [routerLink]="['/accueil']" fragment="prestations">Emballage</a></li>
                        <li><a [routerLink]="['/accueil']" fragment="prestations">Accrochage</a></li>
                        <li><a [routerLink]="['/accueil']" fragment="prestations">Stockage</a></li>
                    </ul>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4 item">
                    <h3>à Propos</h3>
                    <ul>
                        <li><a [routerLink]="['/accueil']" fragment="prestations">Au Service de l'art</a></li>
                        <li><a [routerLink]="['/accueil']" fragment="equipe">L'équipe</a></li>
                        <li><a [routerLink]="['/accueil']" fragment="prestations">Prestations</a></li>
                        <li><a [routerLink]="['/accueil']" fragment="services">Services</a></li>
                    </ul>
                </div>
                <div class="col-md-4 item col-lg-4 text">
                    <h3>Au Service de l'art</h3>
                    <ul>
                        <li><a [routerLink]="['/mentions-legales']" fragment="mentions-legales">Mentions légales</a></li>
                        <li><a [routerLink]="['/mentions-legales']" fragment="utilisations">Conditions d'utilisation</a></li>
                        <li><a [routerLink]="['/mentions-legales']" fragment="droits">Droits d'auteur</a></li>
                        <li><a [routerLink]="['/mentions-legales']" fragment="donnees-personnelles">Données personnelles</a></li>
                    </ul>
                </div>
                <div class="col item social"><a href="#"><i class="fa fa-brands fa-facebook-f"></i></a><a href="#"><i class="fa fa-brands fa-twitter"></i></a><a href="#"><i class="fa fa-brands fa-snapchat"></i></a><a href="#"><i class="fa fa-brands fa-instagram"></i></a></div>
                <p class="copyright">Au Service des Arts © 2023</p>
            </div>
        </div>
    </div>
</footer>