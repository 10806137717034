import { Component, Input, OnInit } from '@angular/core';
import { Membre } from 'src/app/models/membre.model';

@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.css']
})
export class EquipeComponent implements OnInit {

  @Input() membre : Membre;
  constructor() { }

  ngOnInit(): void {
  }

}
