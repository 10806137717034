import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContacteMail } from 'src/app/models/contacte-mail.model';
import emailjs from '@emailjs/browser';

@Component({
  selector: 'app-contactes',
  templateUrl: './contactes.component.html',
  styleUrls: ['./contactes.component.css']
})
export class ContactesComponent implements OnInit {

  contactForm : FormGroup;

  requiredIsFill$: Observable<boolean>;

  isFormFill : boolean;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name : [null, [Validators.required, Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[ \'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$')]],
      subject : [null, [Validators.required]],
      email : [null, [Validators.required, Validators.email]],
      phone : [null, [Validators.pattern('^[0-9]*$')]],
      message : [null, [Validators.required]],
    });

      // Observateur qui émet true lorsque tous les champs obligatoires sont remplis
      this.requiredIsFill$ = this.contactForm.valueChanges.pipe(
        map(() => {
          return this.contactForm.valid;
        })
      );

      this.requiredIsFill$.subscribe((tousLesChampsSontRemplis) => {
        if (tousLesChampsSontRemplis) {
          this.isFormFill = true;
        }
      }
     );
  }

   onSubmit(){
      // Souscription à l'observateur pour émettre l'événement souhaité
        if(this.isFormFill){
          let contactMessage = new ContacteMail(this.contactForm.get('name').value, this.contactForm.get('subject').value, this.contactForm.get('email').value, this.contactForm.get('phone').value, this.contactForm.get('message').value);
          this.sendMail(contactMessage);
        }
    }

   sendMail(contactMessage : ContacteMail){
    emailjs.init('8-FZASA13MulfYCaS')
    emailjs.send("service_ws1xc9m","template_6kljiqy",{
      subject: contactMessage.subject,
      name: contactMessage.name,
      phone: contactMessage.phone,
      email: contactMessage.email,
      message: contactMessage.message,
      });
      alert('Le message a bien été envoyé')
      this.contactForm.reset();
   } 

}

