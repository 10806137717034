    <!-- Start Breadcrumbs -->
    <div class="breadcrumbs">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                    <div class="hero-content">
                        <h1 class="wow fadeInLeft" data-wow-delay=".4s">Devis en ligne</h1>
                        <h6 class="wow fadeInLeft" data-wow-delay=".6s">Un système d’estimation du devis en fonction de vos besoins sera prochainement disponible sur le site. Dans l'intervalle, n’hésitez pas à nous contacter et à nous faire part de vos besoins pour établir votre devis sur mesure.</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section id="contactes">
        <app-contactes></app-contactes>
    </section>
    
    <!-- End Breadcrumbs -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2633.2756974202134!2d2.4449749!3d48.7002112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e60aacdd43fd31%3A0xf583bba7440b3f8!2s78%20Chem.%20du%20Dessus%20du%20Luet%2C%2091230%20Montgeron!5e0!3m2!1sfr!2sfr!4v1708355096655!5m2!1sfr!2sfr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

