import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { AccueilComponent } from './Components/accueil/accueil.component';
import { DevisComponent } from './Components/devis/devis.component';

import { HttpClientModule } from '@angular/common/http';

import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {InputNumberModule} from 'primeng/inputnumber';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';

import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/header/header.component';
import { ShellComponent } from './shared/shell/shell.component';
import { PrestationsComponent } from './Components/accueil/prestations/prestations.component';
import { NosServicesComponent } from './Components/accueil/nos-services/nos-services.component';
import { EquipeComponent } from './Components/accueil/equipe/equipe.component';

import { NgwWowModule } from 'ngx-wow';
import { ContactesComponent } from './Components/accueil/contactes/contactes.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MentionsLegalesComponent } from './Components/mentions-legales/mentions-legales.component';

/* import { GeocoderResponse } from './models/geocoder-response.model';
import { GeocodingService } from './services/geocoding.service'; */

const PRIMENG_MODULES = [RadioButtonModule, SelectButtonModule, InputNumberModule, ToggleButtonModule, ButtonModule, DividerModule, CardModule, DialogModule, InputTextModule]

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    AccueilComponent,
/*     GeocodingService,
    GeocoderResponse, */
    DevisComponent,
    HeaderComponent,
    PrestationsComponent,
    NosServicesComponent,
    EquipeComponent,
    ContactesComponent,
    FooterComponent,
    MentionsLegalesComponent,
  ],
  imports: [
    NgwWowModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,

    BrowserAnimationsModule,
    ...PRIMENG_MODULES,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
