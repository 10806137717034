import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AccueilComponent } from './Components/accueil/accueil.component';
import { DevisComponent } from './Components/devis/devis.component';
import { ShellComponent } from './shared/shell/shell.component';
import { MentionsLegalesComponent } from './Components/mentions-legales/mentions-legales.component';



// routes
const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '', redirectTo: 'accueil', pathMatch: 'full',
      },
      {
        path: 'accueil',
        component: AccueilComponent,
      },
      {
        path: 'devis',
        component: DevisComponent,
      },
      {
        path: 'mentions-legales',
        component: MentionsLegalesComponent,
      }
    ],
  },
];;

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
