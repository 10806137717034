export class ContacteMail {
   
    name : string;
    subject : string;
    email : string;
    phone : number;
    message : string;

    constructor(name : string, subject : string, email: string, phone : number, message : string) {
        this.name = name;
        this.subject = subject;
        this.email = email;
        this.phone = phone;
        this.message = message;
    }


    public getName() {
        return this.name
    }

    public getSubject() {
        return this.subject;
    } 

    public getEmail(){
        return this.email;
    }

    public getPhone() {
        return this.phone;
    }

    public getMessage(){
        return this.message;
    }
}