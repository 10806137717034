<section id="home" class="hero-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 col-12">
                <div class="hero-content">
                    <h1 class="wow fadeInLeft" data-wow-delay=".4s">Emballage et transport d’oeuvre d’art</h1>
                    <p class="wow fadeInLeft" data-wow-delay=".6s">Nous transportons avec le plus grand soin vos tableaux, statues, mobiliers précieux et objets de valeur avec un savoir-faire de plus de 20 ans.</p>
                    <div class="button wow fadeInLeft" data-wow-delay=".8s">
                        <a (click)="goToDevis()" class="btn">Devis en ligne</a>
                    </div>
                </div>
            </div>
<!--             <div class="col-lg-7 col-md-12 col-12">
                <div class="hero-image wow fadeInRight" data-wow-delay=".4s">
                    <img src="assets/images/hero/phone.png" alt="#">
                </div>
            </div> -->
        </div>
    </div>
</section>

<section id="prestations" class="app-info section">
    <div class="container">
      <div class="row">
        <div class="col-12">
            <div class="section-title">
                <h3 class="wow zoomIn" data-wow-delay=".2s">prestations</h3>
                <h2 class="wow fadeInUp" data-wow-delay=".4s">Nos prestations en quelques mots...</h2>
                    <p class="wow fadeInUp" data-wow-delay=".6s">Notre entreprise familiale opère dans le transport d’œuvres d’art et d’objets précieux en Europe. Nous sommes particulièrement reconnus pour la qualité de nos accrochages. Notre lieu de stockage se situe aux portes de Paris, à Pantin.
                    </p>
            </div>
        </div>
    </div>
    <app-prestations *ngFor="let prestation of listePrestations" [prestation]="prestation" ></app-prestations>   
    </div>
</section>

<section id="services" class="features section">
  <div class="container">
      <div class="row">
        <div class="col-12">
            <div class="section-title">
                <h3 class="wow zoomIn" data-wow-delay=".2s">services</h3>
                <h2 class="wow fadeInUp" data-wow-delay=".4s">Informations importantes à connaître...</h2>
                <p class="wow fadeInUp" data-wow-delay=".6s">Nos gammes élargies de services permettent de satisfaire tous les besoins de vos œuvres.
                </p>
            </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let service of listeNosServices" class="col-lg-4 col-md-6 col-12">
            <app-nos-services  [service]="service"></app-nos-services>
        </div>
      </div>
  </div>
</section>
  

<!-- <section id="video-prestations" class="app-info section" >
 <h1 class="title-prestation border-title-prestation">Notre expertise</h1><h1 class=title-prestation-suite>&nbsp;en vidéo</h1>
<div class="row">
    <div class="col-12">
        <div class="section-title">
            <h2 class="wow fadeInUp" data-wow-delay=".4s">Notre expertise en vidéo</h2>
            <p class="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
                Ipsum available, but the majority have suffered alteration in some form.
            </p>
        </div>
    </div>
  </div>
    <div id="video-prestation" class="mt-30">
        <iframe width="1000" height="600" src="https://www.youtube.com/embed/G9H778SXKQo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
    </div>
</section> -->

    <!-- Start Client Logo Section Area -->
<section id="logos" class="section">
    <div class="client-logo-section">
         <div class="container"> 
             <div class="row">
                <div class="col-12">
                    <div class="section-title">
                        <h3 class="wow zoomIn" data-wow-delay=".2s">partenaires</h3>
                        <h2 class="wow fadeInUp" data-wow-delay=".4s">Nos partenaires de prestige...</h2>
                        <p class="wow fadeInUp" data-wow-delay=".6s">Ils nous ont fait confiance et nous leur rendons bien.
                        </p>
                    </div>
                </div>
              </div> 
            <div class="row client-logo-row"> 
                <div class="client-logo-wrapper">
                    <div class="client-logo-carousel d-flex align-items-center justify-content-between">
                        <div class="client-logo">
                            <img src="../../assets/img/LogoChristies.jpeg" alt="">
                        </div>
                        <div class="client-logo">
                            <img src="../../assets/img/LogoNatalie.jpeg" alt="">
                        </div>
                        <div class="client-logo">
                            <img src="../../assets/img/LogoTamenaga.jpeg" alt="">
                        </div>
                        <div class="client-logo">
                            <img src="../../assets/img/LogoHelene.jpeg" alt="">
                        </div>
                        <div class="client-logo">
                            <img src="../../assets/img/LogoChristies.jpeg" alt="">
                        </div>
                        <div class="client-logo">
                            <img src="../../assets/img/LogoNatalie.jpeg" alt="">
                        </div>
                        <div class="client-logo">
                            <img src="../../assets/img/LogoTamenaga.jpeg" alt="">
                        </div>
                        <div class="client-logo">
                            <img src="../../assets/img/LogoHelene.jpeg" alt="">
                        </div>
                    </div>
                </div>
            </div>
         </div> 
    </div>
</section>
    <!-- End Client Logo Section Area -->


  <section id="equipe" class="team section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h3 class="wow zoomIn" data-wow-delay=".2s">Equipe</h3>
                    <h2 class="wow fadeInUp" data-wow-delay=".4s">Rencontrez notre équipe</h2>
                    <p class="wow fadeInUp" data-wow-delay=".6s">Une histoire de famille, de passion et de compétences mutualisées dans un seul but : mettre l’excellence au service de vos œuvres.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let directeur of listeDirecteurs" class="col-lg-4 col-md-6 col-12">
                <app-equipe  [membre]="directeur"></app-equipe>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let associe of listeAssociees" class="col-lg-4 col-md-6 col-12">
                <app-equipe  [membre]="associe"></app-equipe>
            </div>
        </div>
    </div>
</section>

<section id="contactes">
    <app-contactes></app-contactes>
</section>
  <!-- <div (click)="goTop()" [ngClass]="changeTop ? 'button-scroll-top' : 'button-scroll-top-none'"><i class="fa fa-solid fa-arrow-up"></i></div> -->
<a [routerLink]="[]" fragment="" (click)="goTop()" class="scroll-top">
    <i class="lni lni-chevron-up"></i>
</a>