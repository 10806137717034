import { AfterViewInit, Component, OnInit } from '@angular/core';
import { tns } from "tiny-slider/src/tiny-slider"
import { Router } from '@angular/router';
import { Membre } from 'src/app/models/membre.model';
import { NosServices } from 'src/app/models/nos-services.model';
import { Prestation } from 'src/app/models/prestation.model';
import { ASSOCIEES_VALUES, DIRECTEURS_VALUES, NOS_SERVICES_VALUES, PRESTATIONS_VALUES, LOGO_VALUES } from 'src/assets/data';
import { NgwWowService } from 'ngx-wow';
import { LogoPartenaire } from 'src/app/models/logo-partenaires.model';


@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit, AfterViewInit {
  
  changeTop = false;
  listePrestations : Prestation[] = PRESTATIONS_VALUES;
  listeNosServices : NosServices[] = NOS_SERVICES_VALUES;
  listeDirecteurs : Membre[] = DIRECTEURS_VALUES;
  listeAssociees : Membre[] = ASSOCIEES_VALUES;
  listeLogos : LogoPartenaire[] = LOGO_VALUES;

  constructor(private router: Router, private wowService: NgwWowService) {  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    tns({
      container: '.client-logo-carousel',
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      gutter: 15,
      nav: false,
      controls: false,
      responsive: {
          0: {
              items: 1,
          },
          540: {
              items: 2,
          },
          768: {
              items: 3,
          },
          992: {
              items: 4,
          }
      }
  });
}
  
  goTop(){
    window.scroll({ 
      top: 0, 
      behavior: 'smooth' 
    });
  }

  //Redirection vers la page de devis en ligne
  goToDevis(){
    let link = ['/devis'];
    this.router.navigate(link);
  }

}
