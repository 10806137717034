   <!-- Start Contact Form Head Area -->
<div class="contact-form-head section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-12">
                <div class="contact-inner-title">
                    <h2>Une question ? <br>Envoyez-nous un message.</h2>
                    <br>
                    <p>Nous vous répondrons dans les meilleurs délais.</p>
                    <ul class="day-list">
                        <li>Lundi - Vendredi:</li>
                        <li>9h00 - 19h00</li>
                        <li></li>
                        <li>78 chemin du dessus du Luet 91230 Montgeron</li>
                        <li>auservicedesarts@outlook.com</li>
                        <li>06 68 40 69 70</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
                <div class="form-main">
                    <form [formGroup] = "contactForm" (ngSubmit)="onSubmit()" class="form">
                        <div class="row">

                              <!-- Name -->
                            <div class="col-lg-6 col-12">
                                <div class="form-group" [class.has-error]="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)">
                                    <input pInputText formControlName="name" type="text" placeholder="Votre Nom *">
                                    <div *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)">
                                      <small class="form-text text-danger">Le nom est requis.</small>
                                    </div>
                                  </div>
                            </div>

                              <!-- subject -->
                            <div class="col-lg-6 col-12">
                                <div class="form-group" [class.has-error]="contactForm.get('subject').invalid && (contactForm.get('subject').dirty || contactForm.get('subject').touched)">
                                    <input pInputText formControlName="subject" type="text" placeholder="Objet *">
                                    <div *ngIf="contactForm.get('subject').invalid && (contactForm.get('subject').dirty || contactForm.get('subject').touched)">
                                      <small class="form-text text-danger">L'object du mail est requis.</small>
                                    </div>
                                  </div>
                            </div>

                              <!-- email -->
                            <div class="col-lg-6 col-12">
                                <div class="form-group" [class.has-error]="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                                    <input pInputText formControlName="email" type="text" placeholder="Votre Email *">
                                    <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                                      <small class="form-text text-danger">Une adresse email valide est requise.</small>
                                    </div>
                                  </div>
                            </div>

                                <!-- phone -->                                  
                            <div class="col-lg-6 col-12">
                                <div class="form-group" [class.has-error]="contactForm.get('phone').invalid && (contactForm.get('phone').dirty || contactForm.get('phone').touched)">
                                    <input pInputText formControlName="phone" type="text" placeholder="Votre Téléphone">
                                    <div *ngIf="contactForm.get('phone').invalid && (contactForm.get('phone').dirty || contactForm.get('phone').touched)">
										<small class="form-text text-danger">Veuillez saisir un numéro de téléphone valide</small>
                                    </div>
                                  </div>
                            </div>

                               <!-- message -->
                            <div class="col-12">
                                <div class="form-group message" [class.has-error]="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)">
                                    <textarea formControlName="message" type="text" placeholder="Votre Message *"></textarea>
                                    <div *ngIf="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)">
                                      <small class="form-text text-danger">Un message de mail est requis.</small>
                                    </div>
                                  </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group button">
                                    <button type="submit" class="btn ">Envoyer</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>