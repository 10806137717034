import { Component, OnInit, Input } from '@angular/core';
import { NosServices } from 'src/app/models/nos-services.model';

@Component({
  selector: 'app-nos-services',
  templateUrl: './nos-services.component.html',
  styleUrls: ['./nos-services.component.css']
})
export class NosServicesComponent implements OnInit {

  constructor() { }

  @Input() service: NosServices;

  ngOnInit(): void {
  }

}
