<!-- Start Single Feature -->
<div class="single-feature wow fadeInUp" data-wow-delay=".4s">
    <!-- <span class="icone-center"><i [class]="service.icone"></i></span> -->
    <span class="icone-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" role="img">
          <path fill="currentColor" [attr.d]="service.icone"></path>
        </svg>
      </span>
    <h3>{{service.titre}}</h3>
    <p>{{service.paragraphe}}</p>
</div>
<!-- End Single Feature -->
