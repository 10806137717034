import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  ngOnInit(): void {
  }
  title = 'services-art';
  changeTop = false;
  isAccueil = false;
  isActive = false;

  constructor(private router: Router) {
    router.events.subscribe((val) => {
        if (router.url === '/accueil') {
            this.isAccueil = true;
        } else {
            this.isAccueil = false;
        }
    });
  }

  toggleNavbar() {
    this.isActive = !this.isActive;
  }

 /*  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if(window.pageYOffset > 50 && this.isAccueil == true)
    {
      this.changeTop = true;
    }
    else {this.changeTop = false;}
  } */

  goTop(){
    window.scroll({ 
      top: 0, 
      behavior: 'smooth' 
    });
  }

    //Redirection vers la page de devis en ligne
    goToDevis(){
      let link = ['/devis'];
      this.router.navigate(link);
    }

    //Redirection vers la page d'accueil
    goToAccueil(){
      let link = ['/accueil'];
      this.router.navigate(link);
    }


}
